import {DPCalendarBlock, DPEvent} from 'types/DPTypes'

import {Icon} from 'assets/icon'
import {Block} from 'blocks/block'
import {Theme} from 'layout/theme'
import React from 'react'
import {cleanDate} from 'util/createEventInfo'
import {useDpDateToPrettyDate} from 'util/dates'
import {Link} from 'util/link'
import {useLinks} from 'util/links'
import {fromModule} from 'util/styler/Styler'
import {useIDFromAnchorBlock} from 'util/useAnchor'
import css from './calendar.module.scss'

const styles = fromModule(css)

export const Calendar: React.FC<DPCalendarBlock> = (data) => {
	const {_type, title, events, content, link} = data
	const {calendar} = useLinks()
	const id = useIDFromAnchorBlock(data)

	if (!events || events.length === 0) return null

	if (content)
		return (
			<CalendarContentView
				events={events.slice(0, 3)}
				title={title || calendar.title}
				content={content}
				calendarurl={link && link.url ? link.url : calendar.url}
			/>
		)

	return (
		<Block type={_type} className={styles.calendar()} anchorID={id}>
			<Theme.Container>
				<Link href={link && link.url ? link.url : calendar.url}>
					<Theme.H2 mod={['blueline', 'arrow']}>
						{title || calendar.title}
					</Theme.H2>
				</Link>
				<div className={styles.calendar.block()}>
					<CalendarEvents events={events.slice(0, 6)} />
				</div>
			</Theme.Container>
		</Block>
	)
}

const CalendarContentView: React.FC<{
	events: DPEvent[]
	title: string
	content: string
	calendarurl: string
}> = ({events, title, content, calendarurl}) => {
	return (
		<div className={styles.calendar.mod('contentview')()}>
			<div className={styles.contentview()}>
				<div className={styles.contentview.list()}>
					<Link href={calendarurl}>
						<Theme.H2 mod={['blueline', 'arrow', 'white']}>{title}</Theme.H2>
					</Link>
					<CalendarEvents events={events} contentview={true} />
				</div>
				<div className={styles.contentview.content()}>
					<Theme.Wysiwyg className={styles.contentview.content.text()}>
						{content}
					</Theme.Wysiwyg>
				</div>
			</div>
		</div>
	)
}

const CalendarEvents: React.FC<{
	events: Array<DPEvent>
	contentview?: boolean
}> = (data) => {
	const {events, contentview} = data
	const {printDate} = useDpDateToPrettyDate()
	if (!events || events.length === 0) return null

	return (
		<div className={styles.events.mod({contentview})()}>
			{events.map((event, i) => {
				const date = cleanDate(event.date, event.end_date)
				return (
					<Link
						key={i}
						href={event.url || ''}
						className={styles.events.item.mod({contentview})()}
					>
						<div className={styles.events.item.wrapper()}>
							{date && <p className={styles.events.item.date()}>{date}</p>}
							<Theme.H4 mod="light">{event.title}</Theme.H4>
							{event?.location && (
								<p className={styles.events.item.location()}>
									<span className={styles.events.item.location.icon()}>
										<Icon icon="Location" />
									</span>
									{event.location}
								</p>
							)}
						</div>
					</Link>
				)
			})}
		</div>
	)
}
