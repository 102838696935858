import {DPDate} from 'types/DPTypes'
import {dpDateToJsDate} from 'util/dates'
import {drupalLang} from 'util/locale'

export const createEventInfo = (params: {
	date: DPDate
	end_date: DPDate
	location?: string
}) => {
	const {date, end_date, location} = params

	let eventInfo = ''
	if (date) {
		eventInfo = eventInfo.concat(cleanDate(date, end_date))
		if (location) eventInfo = eventInfo.concat(' | ')
	}
	if (location) eventInfo = eventInfo.concat(location)

	return eventInfo
}

export const cleanDate = (date, endDateInitial?) => {
	const options = {year: 'numeric', month: 'long', day: 'numeric'} as const
	const optionsMonth = {month: 'long'} as const
	const locale = drupalLang === 'nl' ? 'nl-NL' : 'en'

	const year = date.substring(0, 4)
	const month = date.substring(5, 7)
	const day = date.substring(8, 10)

	const startDate = dpDateToJsDate(date)
	const startDateString = startDate.toLocaleDateString(locale, options)

	if (!endDateInitial) {
		return startDateString
	}

	const endYear = endDateInitial.substring(0, 4)
	const endMonth = endDateInitial.substring(5, 7)
	const endDay = endDateInitial.substring(8, 10)

	const endDate = new Date(endYear, endMonth - 1, endDay)

	if (month === endMonth && year === endYear) {
		const monthString = startDate.toLocaleDateString(locale, optionsMonth)
		if (drupalLang === 'nl') return `${day} - ${endDay} ${monthString} ${year}`
		return `${monthString} ${day} - ${endDay}, ${year}`
	}

	if (year === endYear) {
		const startMonthString = startDate.toLocaleDateString(locale, optionsMonth)
		const endMonthString = endDate.toLocaleDateString(locale, optionsMonth)
		if (drupalLang === 'nl')
			return `${day} ${startMonthString} - ${endDay} ${endMonthString} ${year}`
		return `${startMonthString} ${day} - ${endMonthString} ${endDay}, ${year}`
	}

	return startDateString.concat(
		' - ',
		endDate.toLocaleDateString(locale, options)
	)
}
